import { Routes, Route } from "react-router-dom";
import UserSettings from "./User"
import EditOrg from "./Org/Edit"

const Settings = ({ user, access, setUser }) => {

    return (
        <Routes>
            <Route path="/user" element={<UserSettings user={user} setUser={setUser} />} />
            <Route path="/org/:org" element={<EditOrg user={user} access={access} />} />
        </Routes>
    )

}

export default Settings;