import React from "react";
import { Logout } from "../../../api";
import { Menu, MenuItem, Avatar, IconButton, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
//import BusinessIcon from '@mui/icons-material/Business';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';

const initials = name => {
  name = name.split(',')[0]
  return name.split(' ').slice(0,3).map(e=>e[0]).join('')
}

const SubMenu = ({ name , access }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOut = async (e) => {
    Logout().then(() => navigate('/'));
  };

  return (
    <>
      <IconButton aria-expanded={open} onClick={handleClick} size="small">
        <Avatar>{initials(name)}</Avatar>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem component={Link} to="/settings/user">
          <ListItemIcon>
            <ManageAccountsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>User Settings</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem  onClick={(e) => logOut(e)}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Log out</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SubMenu;
