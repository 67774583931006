import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Grid,
  Button,
  FormControl,
  Typography,
  TextField,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { PictureAsPdf as PictureAsPdfIcon } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import CompleteIcon from "@mui/icons-material/CheckCircle";
import { useParams, useNavigate } from "react-router-dom";
import { usePrompt } from "../../../../../../common/usePrompt"
import { GetStudy, UpdateAddendum, CompleteStudy } from "../../../../../../api";
import RichText from "../../../../../../common/Editor";
import ReportingData from "../Respond/ReportingData";
import PdfPreview from "../../../shared/PdfPreview"
import TextFieldsIcon from '@mui/icons-material/TextFields';

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];
let changeTimeout

const StudyAddendum = () => {
  const params = useParams();
  const [content, setContent] = useState({
    Addendum: { label: "Addendum" }
  });
  const [study, setStudy] = useState(false);
  const [open, setOpen] = useState(false);
  const [newField, setNewField] = useState("");
  const navigate = useNavigate();
  const [pdfPreviewDialogState, setPdfPreviewDialogState] = useState({
    open: false,
    studyId: null,
  });
  const [changes, setChanges] = useState(true)
  const [autoSave, setAutoSave] = useState()

  const updateAddendum = useCallback(() => UpdateAddendum(study.id, {report: content}).then(()=>setChanges(false)).catch(e=>e), [study.id, content])

  const goBack = useCallback(num => {
    if (window.history.state && window.history.state.idx > 0) navigate(num)
    else navigate('/', { replace: true })
  }, [navigate])

  useEffect(() => {
    if (params.studyId !== study.id)
      GetStudy(params.studyId).then(resp => {
        if ("addendum" in resp) setContent((e) => Object.assign(e, resp.addendum));

        setStudy(resp);
      }).catch(e=>e)
      return () => {
        if (changeTimeout) {
          clearTimeout(changeTimeout)
          changeTimeout = undefined 
        }
      }
  }, [params.studyId, study.id])

  useEffect(() => {
    if (autoSave) {
      updateAddendum().then(() =>
        console.log('Auto saved')
      )
      setAutoSave()
    }
  }, [updateAddendum, autoSave, content, study])

  usePrompt("There are unsaved changes, are you sure you want to leave the page?", changes)

  if (!study) return <React.Fragment />;

  const handleSubmit = async (e) => {
    e.preventDefault();

    updateAddendum().then(() => goBack(-1));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePdfPreviewDialog = async () => {
    updateAddendum().then(() =>
      setPdfPreviewDialogState({ open: true, studyId: `${study.id}` })
    )
  };

  const handleSaveDialog = () => {
    const fieldShort = newField.replace(/[\W]/g, "");
    if (fieldShort.length && !(fieldShort in content)) {
      setContent((e) => ({ ...e, [fieldShort]: { label: newField } }));
      handleClose();
    }
  };

  const change = (field, value) => {
    setContent((e) => {
      const copyContent = { ...e };
      const copyKey = { ...copyContent[field] };
      copyKey.content = JSON.stringify(value);
      // Check if empty
      if (value && value.length === 1 && value[0].children && value[0].children.length === 1 && !value[0].children[0].text)
        value = undefined
      else if (copyKey.content && !changeTimeout)
        changeTimeout = setTimeout(() => {
          setAutoSave(true)
          changeTimeout = undefined
        }, 15000)
      copyKey.content = JSON.stringify(value);
      copyContent[field] = copyKey;
      return copyContent;
    });
  };

  const completeStudy = (id) => {
    updateAddendum().then(() =>
      CompleteStudy(id).then(() => goBack(-1)).catch(e=>e)
    )
  }

  return (
    <Container>
      <ReportingData study={study} report={true} />
      <form onSubmit={handleSubmit}>
        <Grid
          sx={{ mb: 3 }}
          container
          spacing={4}
          justifyContent="left"
          maxWidth="md"
        >
          {Object.entries(content).map(([key, val]) => (
            <Grid key={key} item sm={12}>
              <FormControl fullWidth variant="standard">
                <Typography component="h2" variant="h6" color={(val.label && val.label[0] === '*' && 'secondary') || 'default'}>
                  {(val.label ? (val.label[0] === '*' && val.label.slice(1)) || val.label : '')}:
                </Typography>
                <RichText
                  initialValue={val.content ? JSON.parse(val.content) : initialValue}
                  onChange={(e) => change(key, e)}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
        <Grid container sx={{ mb: 3 }} spacing={3} justifyContent="center">
          <FormControl fullWidth variant="standard">
            <Button color="secondary" onClick={handleClickOpen} startIcon={<TextFieldsIcon />}>
              Add Field
            </Button>
          </FormControl>
          <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
            <DialogTitle>Add Field</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add a rich text field to the report for this study
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="desc"
                label="Field Name"
                type="desc"
                fullWidth
                variant="standard"
                onChange={(e) => setNewField(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSaveDialog}>Save</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid container spacing={3} justifyContent="left">
          <Grid item>
            <Button variant="outlined" color="primary" type="submit" startIcon={<SaveIcon />}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => completeStudy(study.id)}
              startIcon={<CompleteIcon />}
              sx={{"&:disabled": {backgroundColor: 'rgba(206, 147, 216, 0.25)'}}}
            >
              Complete
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="success"
              onClick={handlePdfPreviewDialog}
              startIcon={<PictureAsPdfIcon />}
            >
              Pdf Preview
            </Button>
          </Grid>
        </Grid>
      </form>
      <PdfPreview
        open={pdfPreviewDialogState.open}
        studyId={pdfPreviewDialogState.studyId}
        setDialogState={setPdfPreviewDialogState}
      />
    </Container>
  );
};

export default StudyAddendum;
