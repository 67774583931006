import axios from "axios";
import io from "socket.io-client";

export const api = "https://api.pacs.corridor.vet/";
//export const api = "http://localhost:3600/";

export const zipApi = "https://zip.pacs.corridor.vet/";

let token = localStorage.getItem("token");
let subs = {};
export let socket;

let setAuthenticated = () => {};
let pushAlert, socketStatus

initSocket();
export function setAuthenticatedCallback(callback) {
  setAuthenticated = callback;
}
export function setPushAlert(callback) {
  pushAlert = callback;
}
export function onSocketStatus(callback) {
  socketStatus = callback;
}

const handleVisibilityChange = () => {
  if (document.visibilityState === 'hidden')
    closeSocket()
  else if (document.visibilityState === 'visible')
    initSocket()
}
document.addEventListener('visibilitychange', handleVisibilityChange)

export async function apiFetch(params) {
  console.log("API call: " + params.endpoint);
  return axios({
    url: `${api}${params.endpoint}`,
    method: params.method || "GET",
    headers: Object.assign(
      {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      token ? { Authorization: `Bearer ${token}` } : null,
      params.headers
    ),
    data: params.data,
    params: params.params,
    signal: params.abortSignal,
    responseType: params.responseType || 'json',
    retries: params.retries || 3
  }).catch(e => {
    if (e.response && e.response.status === 403)
      // Forbidden - Log user out
      Logout()
    else throw e
  });
}

const dataPromise = async (p, n) => new Promise((resolve, reject) =>
  p.then(r => (r && typeof r.data !== 'undefined' && resolve(r.data)) || reject(r)).catch(e => reject(e))
).catch(e => {
  if (!n && pushAlert) {
    //const message = e.response?.data?.errors || (['ERR_NETWORK'].includes(e.code) && e.message) || undefined
    const message = e.response?.data?.errors || undefined
    if (message)
      pushAlert({severity: 'error', message, timestamp: new Date()})
  }
  throw e
})

async function initSocket() {
  await closeSocket()
  if (token) {
    socket = io(api, { auth: { token: token }, transports: ['websocket'] })
    socket.on('connect', () => socketStatus && socketStatus(true))
    socket.on('disconnect', () => socketStatus && socketStatus(false))
    socket.on("sub", (data) => {
      const mcb = (d) => console.log("Missing callback for subscription", d);
      if (data.coll in subs)
        if ("type" in data && data.type in subs[data.coll])
          if ("id" in data && data.id in subs[data.coll][data.type])
            if ("sub" in subs[data.coll][data.type][data.id])
              subs[data.coll][data.type][data.id].sub(data);
            else mcb(data);
          else if ("sub" in subs[data.coll][data.type])
            subs[data.coll][data.type].sub(data);
          else mcb(data);
        else if ("sub" in subs[data.coll]) subs[data.coll].sub(data);
        else mcb(data);
    })
  }
}
async function closeSocket() {
  if (socket) socket.disconnect();
}

export async function Subscribe(e, callBack) {
  if ("coll" in e) {
    if (!(e.coll in subs)) subs[e.coll] = {};
    if ("type" in e && !(e.type in subs[e.coll])) subs[e.coll][e.type] = {};
    if ("id" in e && !(e.id in subs[e.coll][e.type]))
      subs[e.coll][e.type][e.id] = {};

    socket.emit("sub", e);
    if ("type" in e)
      if ("id" in e) subs[e.coll][e.type][e.id].sub = callBack;
      else subs[e.coll][e.type].sub = callBack;
    else subs[e.coll].sub = callBack;

    console.log("Subscribe call:", e);
  }
}

export async function Unsubscribe(e, id) {
  if ("coll" in e) {
    socket.emit("unsub", e);
    if ("type" in e)
      if ("id" in e) delete subs[e.coll][e.type][e.id].sub;
      else delete subs[e.coll][e.type].sub;
    else delete subs[e.coll].sub;
    console.log("Unsubscribe call:", e);
  }
}

export async function Login(email, password) {
  await Logout()
  return dataPromise(
    apiFetch({
      method: "POST",
      endpoint: "auth",
      data: { email: email, password: password },
    })
  ).then(response => {
    if (response.accessToken && response.refreshToken) {
      localStorage.setItem("token", (token = response.accessToken))
      initSocket()
    } else throw new Error("Unknown error")
  })
}

export async function CreateAccount(data) {
  return dataPromise(
    apiFetch({
      method: "POST",
      endpoint: "users",
      data
    })
  ).then(response => {
    if (response.id) localStorage.setItem("token", (token = response.id))
    else throw new Error("Unknown error")
  })
}

export async function Logout() {
  localStorage.removeItem("token")
  token = undefined
  setAuthenticated(false)
  await closeSocket()
}
export function CheckLogin() {
  return !!token;
}
export async function VerifyUser(email, code) {
  return dataPromise(
    apiFetch({
      method: "POST",
      endpoint: "users/verify",
      data: { email: email, code: code },
    })
  )
}
export async function VerifyResend(email) {
  return dataPromise(
    apiFetch({
      method: "POST",
      endpoint: "users/verify/resend",
      data: { email: email },
    })
  )
}
export async function GetUser(userId) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "users" + (userId ? "/" + userId : ""),
    })
  )
}
export async function NewStudy(data) {
  return dataPromise(
    apiFetch({
      method: "POST",
      endpoint: "studies",
      data,
    })
  )
}
export async function UpdateUser(userId, data) {
  return dataPromise(
    apiFetch({
      method: "PATCH",
      endpoint: "users" + (userId ? "/" + userId : ""),
      data,
    })
  )
}
export async function GetStudy(studyId, params, abortSignal) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "studies" + (studyId ? "/" + studyId : ""),
      params,
      abortSignal
    })
  )
}
export async function GetStudyCount(params, abortSignal) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "studies/count",
      params,
      abortSignal
    })
  , true)
}
export async function ClaimStudy(studyId) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "studies/" + studyId + "/claim",
    })
  )
}
export async function UnClaimStudy(studyId) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "studies/" + studyId + "/unclaim",
    })
  )
}
export async function UploadInstance(files, params, callBack) {
  let resp = [];
  // Send one-by-one since server is keeping them in memory to offload to DICOM server
  for (const file of files) {
    let fd = new FormData();
    fd.append("file", file);
    // await, don't overload server
    await dataPromise(
      apiFetch({
        method: "POST",
        endpoint: "instances",
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
        params
      })
    ).then(response => {
      resp.push(response)
      if (callBack) callBack(file, response)
    })
  }
  return new Promise((r,j) => r(resp))
}
export async function UpdateStudy(studyId, data) {
  return dataPromise(
    apiFetch({
      method: "PATCH",
      endpoint: "studies" + (studyId ? "/" + studyId : ""),
      data,
    })
  )
}
export async function GetOrg(orgId, params) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "orgs" + (orgId ? "/" + orgId : ""),
      params,
    })
  )
}
export async function GetOrgChildren(orgId, params) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "orgs/" + orgId + "/children",
      params,
    })
  )
}
export async function UpdateConsult(studyId, data) {
  return dataPromise(
    apiFetch({
      method: "PATCH",
      endpoint: "studies/" + studyId + "/consult",
      data
    })
  )
}
export async function UpdateReport(studyId, data) {
  return dataPromise(
    apiFetch({
      method: "PATCH",
      endpoint: "studies/" + studyId + "/report",
      data,
    })
  )
}
export async function GetReport(studyId, filename) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint:
        "studies/" + studyId + "/report/" + (filename ? filename + ".pdf" : ""),
    })
  )
}
export async function GetReportHtml(studyId) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint:
        "studies/" + studyId + "/report/html",
    })
  )
}
export async function UploadReportFile(files, callBack) {
  let resp = [];
  // Send one-by-one since server is keeping them in memory to offload to DICOM server
  for (const file of files) {
    let fd = new FormData();
    fd.append("file", file);
    await dataPromise(
      apiFetch({
        method: "POST",
        endpoint: "studies/report",
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
      })
    ).then(reponse => {
      resp.push(reponse)
      if (callBack) callBack(file, reponse)
    })
  }
  return new Promise((r,j) => r(resp))
}
export async function SendStudy(studyId) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "studies/" + studyId + "/send",
    })
  )
}
export async function CompleteStudy(studyId) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "studies/" + studyId + "/complete",
    })
  )
}
export async function DeleteStudy(studyId) {
  return dataPromise(
    apiFetch({
      method: "DELETE",
      endpoint: "studies/" + studyId,
    })
  )
}
export async function ClearStudy(studyId) {
  return dataPromise(
    apiFetch({
      method: "DELETE",
      endpoint: "studies/" + studyId + "/clear",
    })
  )
}
export async function GetStudyJson(studyId) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "studies/" + studyId + "/json",
    })
  )
}
export async function UpdateAddendum(studyId, data) {
  return dataPromise(
    apiFetch({
      method: "PATCH",
      endpoint: "studies/" + studyId + "/addendum",
      data,
    })
  )
}
export async function ReOrg(studyId, data) {
  return dataPromise(
    apiFetch({
      method: "POST",
      endpoint: "studies/" + studyId + "/reorg",
      data,
    })
  )
}
export async function SendReport(studyId) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "studies/" + studyId + "/report/send"
    })
  )
}
export async function SendPackage(studyId, data) {
  return dataPromise(
    apiFetch({
      method: "POST",
      endpoint: "studies/" + studyId + "/package/send",
      data
    })
  )
}
export async function GetToken(data) {
  return dataPromise(
    apiFetch({
      method: "POST",
      endpoint: "auth/token",
      data
    })
  )
}
export async function GetInstanceThumb(instanceId) {
  return new Promise (res => {
    apiFetch({
      method: "GET",
      endpoint: "instances/" + instanceId + "/thumb",
      headers: {
        Accept: 'image/*'
      },
      responseType: 'blob'
    }).then(response => {
      const reader = new FileReader();
      reader.onloadend = res
      reader.readAsDataURL(response.data);
    }).catch(e => e)
  }).then(r => r.target?.result)
}
export async function fetchDicom(uri, studyDesc) {
  return fetch(uri).then(r=>(r.ok && r) || pushAlert({severity: 'error', message: 'Failed to get DICOM in '+studyDesc, timestamp: new Date()}))
}
export async function MergeStudies(studyId, data) {
  return dataPromise(
    apiFetch({
      method: "POST",
      endpoint: "studies/" + studyId + "/merge",
      data
    })
  )
}
export async function DuplicateStudy(studyId) {
  return dataPromise(
    apiFetch({
      method: "GET",
      endpoint: "studies/" + studyId + "/duplicate"
    })
  )
}
export async function AssignStudy(studyId, data) {
  return dataPromise(
    apiFetch({
      method: "POST",
      endpoint: "studies/" + studyId + "/requestee",
      data
    })
  )
}