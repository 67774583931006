import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  Container,
  Grid,
  Button,
  FormControl,
  Typography,
  TextField,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem
} from "@mui/material";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Box
} from "@mui/material";
import { PictureAsPdf as PictureAsPdfIcon, BrokenImage as BrokenImageIcon } from "@mui/icons-material";
import dayjs from 'dayjs';
import SaveIcon from '@mui/icons-material/Save';
import CompleteIcon from "@mui/icons-material/CheckCircle";
import { useParams, useNavigate } from "react-router-dom";
import { usePrompt } from "../../../../../../common/usePrompt"
import { GetStudy, UpdateReport, CompleteStudy, GetToken } from "../../../../../../api";
import RichText from "../../../../../../common/Editor";
import { PRIORITIES } from "../../../../../../constants/Portal/Dashboard/Studies/Consult/Misc";
import ReportingData from "./ReportingData";
import PdfPreview from "../../../shared/PdfPreview"
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { getFilename } from '../../../shared/fileDrop';

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];
const SkeletonList = props =>
  <Box sx={{width: 300}}>
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </Box>
let changeTimeout

const StudyRespond = ({ access }) => {
  const params = useParams();
  const [content, setContent] = useState({
    Diagnostic: { label: "Diagnostic interpretation" },
    Conclusions: { label: "Conclusions" },
    Comments: { label: "Comments & Recommendations" },
  });
  const [study, setStudy] = useState(false);
  const [studies, setStudies] = useState();
  const [open, setOpen] = useState(false);
  const [newField, setNewField] = useState("");
  const [billing, setBilling] = useState([]);
  const [priority, setPriority] = useState();
  const navigate = useNavigate();
  const [pdfPreviewDialogState, setPdfPreviewDialogState] = useState({
    open: false,
    studyId: null,
  });
  const [changes, setChanges] = useState(true)
  const [autoSave, setAutoSave] = useState()

  const updateReport = useCallback(() =>
    UpdateReport(study.id, {report: content, billing: billing.map(e=>e.id), priority}).then(()=>setChanges(false)).catch(e=>e)
  , [study.id, content, billing, priority])

  const goBack = useCallback(num => {
    if (window.history.state && window.history.state.idx > 0) navigate(num)
    else navigate('/', { replace: true })
  }, [navigate])

  useEffect(() => {
    if (params.studyId !== study.id)
      GetStudy(params.studyId).then((resp) => {
        if (resp.org?.billing?.length)
          resp.org.billing.push({name: 'No Charge', id: '64a83ee84024d7967168ef9b'})

        if ("report" in resp) setContent((e) => Object.assign(e, resp.report));

        setStudy(resp)
        setBilling(resp.billing)
        setPriority(resp.priority)

        const org = resp.associateOrg?.id || resp.sentBy?.org?.id || access.org?.id
        const PatientID = resp.PatientMainDicomTags?.PatientID
        if (PatientID && org)
          GetStudy(null, {view: 'patient', org, PatientID}).then(setStudies).catch(e=>e)

      }).catch(e=>e)
      return () => {
        if (changeTimeout) {
          clearTimeout(changeTimeout)
          changeTimeout = undefined 
        }
      }
  }, [params.studyId, study.id, access])

  useEffect(() => {
    if (autoSave) {
      updateReport().then(() =>
        console.log('Auto saved')
      )
      setAutoSave()
    }
  }, [updateReport, autoSave, content, study])

  usePrompt("There are unsaved changes, are you sure you want to leave the page?", changes)

  if (!study) return <React.Fragment />;

  const handleSubmit = async (e) => {
    e.preventDefault();

    updateReport().then(() => goBack(-1));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePdfPreviewDialog = async () => {
    updateReport().then(() =>
      setPdfPreviewDialogState({ open: true, studyId: `${study.id}` })
    )
  };

  const handleSaveDialog = () => {
    const fieldShort = newField.replace(/[\W]/g, "");
    if (fieldShort.length && !(fieldShort in content)) {
      setContent((e) => ({ ...e, [fieldShort]: { label: newField } }));
      handleClose();
    }
  };

  const change = (field, value) => {
    setContent(e => {
      const copyContent = { ...e };
      const copyKey = { ...copyContent[field] };
      // Check if empty
      if (value && value.length === 1 && value[0].children && value[0].children.length === 1 && !(value[0].children[0].text || value[0].children[0].children))
        value = undefined
      else if (copyKey.content && !changeTimeout)
        changeTimeout = setTimeout(() => {
          setAutoSave(true)
          changeTimeout = undefined
        }, 15000)
      copyKey.content = JSON.stringify(value);
      copyContent[field] = copyKey;
      return copyContent;
    });
  };

  const completeStudy = (id) => {
    updateReport().then(() =>
      CompleteStudy(id).then(() => goBack(-1)).catch(e=>e)
    )
  }

  const medDream = StudyInstanceUID => {
    GetToken({data: StudyInstanceUID}).then(result => {
      let { accessToken } = result
      accessToken && window.open(`https://dream.pacs.corridor.vet/?token=${accessToken}`, '_blank')
    }).catch(e=>e)
    return false
  }

  return (
    <Container>
      {priority === null || priority === undefined ? null : (
        <FormControl sx={{mb: 1}} fullWidth variant="standard">
          <TextField
            id="priority"
            value={priority}
            label="Priority"
            onChange={e => setPriority(e.target.value)}
            variant="standard"
            select
            required
          >
            {PRIORITIES.map((e) => (e.id !== '' && !e.hidden &&
              <MenuItem key={e.id} value={e.id}>
                {e.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      )}
      {!!study.org?.billing?.length && (
        <FormControl sx={{mb: 1}} fullWidth variant="standard">
          <Autocomplete
            id="billing"
            freeSolo={false}
            multiple
            name="billing"
            options={(study.org && study.org.billing) || []}
            filterSelectedOptions
            getOptionLabel={o=>o.name}
            onChange={(e,v) => setBilling(v)}
            value={billing}
            isOptionEqualToValue={(o,v)=>!study.org?.settings?.duplicateBillingCodes && o.id===v.id}
            renderInput={(params) => (
              <TextField {...params} required variant="standard" label="Billing Codes" />
            )}
          />
        </FormControl>
      )}
      <ReportingData study={study} />
      {(studies && 
        <List
          sx={{ bgcolor: 'transparent' }}
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListItemButton color="secondary" dense={false} component="a" sx={{px:0}} onClick={() => studies && medDream(studies.filter(e=>e.Series?.length).map(e=>e.MainDicomTags.StudyInstanceUID).join(','))}>
              <ListSubheader disableSticky={true} sx={{bgcolor: 'transparent', fontSize: 'inherit'}} component="div" id="nested-list-subheader" color="primary">
                Studies (view all)
              </ListSubheader>
            </ListItemButton>
          }
        >
          {studies.map(study => {
            const date = new Date(study.MainDicomTags.StudyTimestamp)
            const outputOptions = {
              year: "2-digit",
              month: "numeric",
              day: "numeric",
              hour: study.MainDicomTags.StudyTime ? "numeric" : undefined,
              minute: study.MainDicomTags.StudyTime ? "numeric" : undefined,
            }
            const reportOutputOptions = {
              year: "2-digit",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: study.org?.timezone || 'America/Los_Angeles'
            }
            const reportDate = study.completeAt && dayjs(study.completeAt.last || study.completeAt.first).toDate()
            return (
              <>
                <ListItem disablePadding key={study.id}>
                  <ListItemButton dense={false} disabled={!study.Series?.length} component="a" onClick={() => medDream(study.MainDicomTags.StudyInstanceUID)}>
                    <ListItemIcon><BrokenImageIcon /></ListItemIcon>
                    <ListItemText primary={date.toLocaleString("en-US", outputOptions)} />
                  </ListItemButton>
                </ListItem>
                {reportDate && (
                  <ListItem sx={{ml:2}} disablePadding>
                    <ListItemButton dense={false} component="a" onClick={() => setPdfPreviewDialogState({ open: true, studyId: `${study.id}` })}>
                      <ListItemIcon><PictureAsPdfIcon /></ListItemIcon>
                      <ListItemText primary={reportDate.toLocaleString("en-US", reportOutputOptions)} />
                    </ListItemButton>
                  </ListItem>
                )}
              </>
            )
          })}
        </List>
      ) ||
        <SkeletonList />
      }
      {!!study.media?.length && (
        <List>
          <ListSubheader disableSticky={true} sx={{bgcolor: 'transparent', fontSize: 16}} component="div" id="nested-list-subheader">
            Multimedia
          </ListSubheader>
          {study.media.map((file, index) =>
              <ListItem disablePadding key={file}>
                <ListItemButton component="a" href={file}>
                  <ListItemIcon>
                      <FilePresentIcon />
                  </ListItemIcon>
                  <ListItemText sx={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}} primary={getFilename(file)} />
                </ListItemButton>
              </ListItem>
          )}
        </List>
      )}
      <form onSubmit={handleSubmit}>
        <Grid
          sx={{ mb: 3, mt: 0.5 }}
          container
          spacing={4}
          justifyContent="left"
          maxWidth="md"
        >
          {Object.entries(content).map(([key, val]) => (
            <Grid key={key} item sm={12}>
              <FormControl fullWidth variant="standard">
                <Typography component="h2" variant="h6" color={(val.label && val.label[0] === '*' && 'secondary') || 'default'}>
                  {(val.label ? (val.label[0] === '*' && val.label.slice(1)) || val.label : '')}:
                </Typography>
                <RichText
                  initialValue={val.content ? JSON.parse(val.content) : initialValue}
                  onChange={(e) => change(key, e)}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
        <Grid container sx={{ mb: 3 }} spacing={3} justifyContent="center">
          <FormControl fullWidth variant="standard">
            <Button color="secondary" onClick={handleClickOpen} startIcon={<TextFieldsIcon />}>
              Add Field
            </Button>
          </FormControl>
          <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
            <DialogTitle>Add Field</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add a rich text field to the report for this study
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="desc"
                label="Field Name"
                type="desc"
                fullWidth
                variant="standard"
                onChange={(e) => setNewField(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSaveDialog}>Save</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid container spacing={3} justifyContent="left">
          <Grid item>
            <Button variant="outlined" color="primary" type="submit" startIcon={<SaveIcon />}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              disabled={study.org?.billing?.length && !billing.length}
              onClick={() => completeStudy(study.id)}
              startIcon={<CompleteIcon />}
              sx={{"&:disabled": {backgroundColor: 'rgba(206, 147, 216, 0.25)'}}}
            >
              Complete
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="success"
              onClick={handlePdfPreviewDialog}
              startIcon={<PictureAsPdfIcon />}
            >
              Pdf Preview
            </Button>
          </Grid>
        </Grid>
      </form>
      <PdfPreview
        open={pdfPreviewDialogState.open}
        studyId={pdfPreviewDialogState.studyId}
        setDialogState={setPdfPreviewDialogState}
      />
    </Container>
  );
};

export default StudyRespond;
