import React, { useState, useCallback, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  FormControl,
  Typography,
  TextField,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  Radio,
  ListItemText
} from "@mui/material";
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import EditIcon from '@mui/icons-material/Edit';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import { useNavigate, useParams } from 'react-router-dom';
import { GetOrgChildren } from "../../../../../api";


const EditOrg = ({ user, access }) => {

    const params = useParams();

    const [ submitError, setSubmitError ] = useState()
    const [ childOrgs, setChildOrgs ] = useState()
    const [ childOrg, setChildOrg ] = useState()

    const navigate = useNavigate();

    const handleSubmit = useCallback(e => {
        e.preventDefault()
        navigate('/')
    }, [navigate])

    useEffect(() => {

        GetOrgChildren(params.org).then(r => setChildOrgs(r))

    }, [params.org])

    const handlChildOrgChange = id => {
        setChildOrg(id)
    }

    if (!childOrgs) return <React.Fragment />

    return (
        <Container>
            <form onSubmit={handleSubmit} noValidate>
                <Grid
                    container
                    sx={{ mb: 3 }}
                    spacing={3}
                    justifyContent="left"
                    maxWidth="sm"
                >
                    <Typography component="h1" variant="h4">Clinics</Typography>
                    {submitError ? <FormControl sx={{mb: 1}} fullWidth variant="standard"><Typography component="p" color="error">{submitError}</Typography></FormControl> : null}
                    <FormControl sx={{mb: 1}} fullWidth variant="standard">
                        <List sx={{ maxHeight: 300, overflow: "auto" }}>
                            {childOrgs.map(item => (
                            <ListItem key={item.id} disablePadding>
                                <ListItemButton
                                onClick={() => handlChildOrgChange(item.id)}
                                selected={childOrg === item.id} // Highlights the selected item

                                >
                                <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                            ))}
                        </List>
                    </FormControl>
                </Grid>
                <Grid container spacing={3} justifyContent="left">
                    <Grid item>
                        <Button variant="contained" color="success" startIcon={<DomainAddIcon />}>
                            Create
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" disabled={!childOrg} color="secondary" startIcon={<EditIcon />}>
                            Edit
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" disabled={!childOrg} color="error" startIcon={<DomainDisabledIcon />}>
                            Remove
                        </Button>
                    </Grid>
                </Grid>
                {childOrg && (
                    <>
                        <Grid
                            container
                            sx={{ mt: 5 }}
                            spacing={3}
                            justifyContent="left"
                            maxWidth="sm"
                        >
                            <Typography component="h1" variant="h4">Users</Typography>
                        </Grid>
                    </>
                )}
            </form>
        </Container>
    )

}

export default EditOrg